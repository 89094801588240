import { inject }                from 'aurelia-framework';
import { Router }                from 'aurelia-router';
import { BooleanOption }         from 'modules/administration/models/boolean-option';
import { User }                  from 'modules/administration/models/user';
import { UserEntityTeamLotType } from 'modules/administration/models/user-entity-team-lot-type';
import { UsersRepository }       from 'modules/administration/users/services/repository';

@inject(Router, UsersRepository)
export class AuthenticatedUser {

    lotInterventionSelectionRoute = 'concessions.lot-intervention-selection.index';

    /**
     * Constructor
     *
     * @param router
     * @param usersRepository
     */
    constructor(router, usersRepository) {
        this.router          = router;
        this.usersRepository = usersRepository;
    }

    /**
     * Fetches the logged user from server
     *
     * @returns {*}
     */
    async fetchAuthenticatedUser() {
        const user = await this.usersRepository.authenticatedUser();

        this.user = user;

        return user;
    }

    /**
     * Check whether the user is root
     *
     * @returns {boolean}
     */
    isRoot() {
        return this.user.id === User.ROOT_USER_ID;
    }

    /**
     * Check whether the user is a system reserved user
     *
     * @returns {boolean}
     */
    isSystemReserved() {
        return this.user.system_reserved === BooleanOption.YES;
    }

    /**
     * Check if the user has a role or roles.
     *
     * @param role
     * @param all
     *
     * @returns {boolean}
     */
    is(role, all = false) {
        let roles = this.getArrayFrom(role);

        return all ? this.isAll(roles) : this.isOne(roles);
    }

    /**
     * Check if the user has at least one role.
     *
     * @param roles
     *
     * @returns {boolean}
     */
    isOne(roles) {
        let length = roles.length;

        for (let i = 0; i < length; i++) {
            if (this.hasRole(roles[i])) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if the user has all roles.
     *
     * @param roles
     *
     * @returns {boolean}
     */
    isAll(roles) {
        let length = roles.length;

        for (let i = 0; i < length; i++) {
            if (!this.hasRole(roles[i])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Check if user has a given role
     *
     * @param role
     *
     * @returns {boolean}
     */
    hasRole(role) {
        let allRoles = this.user.roles;
        let length   = allRoles.length;

        for (let i = 0; i < length; i++) {
            if (allRoles[i].slug === role) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if the user has a permission or permissions.
     *
     * @param permission
     * @param all
     *
     * @returns {boolean}
     */
    can(permission, all = false) {
        let permissions = this.getArrayFrom(permission);

        return all ? this.canAll(permissions) : this.canOne(permissions);
    }

    /**
     * Check if the user has at least one permission.
     *
     * @param permissions
     *
     * @returns {boolean}
     */
    canOne(permissions) {
        let length = permissions.length;

        for (let i = 0; i < length; i++) {
            if (this.hasPermission(permissions[i])) {
                return true;
            }
        }

        return length === 0;
    }

    /**
     * Check if the user has all permissions.
     *
     * @param permissions
     *
     * @returns {boolean}
     */
    canAll(permissions) {
        let length = permissions.length;

        for (let i = 0; i < length; i++) {
            if (!this.hasPermission(permissions[i])) {
                return false;
            }
        }

        return length === 0;
    }

    /**
     * Check if user has a given permission
     *
     * @param permission
     *
     * @returns {boolean}
     */
    hasPermission(permission) {
        let allPermissions = this.user.all_permissions;
        let length         = allPermissions.length;

        for (let i = 0; i < length; i++) {
            if (allPermissions[i].slug === permission) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns an argument as an array
     *
     * @param argument
     *
     * @returns {*[]}
     */
    getArrayFrom(argument) {
        return argument.constructor === Array ? argument : [argument];
    }

    /**
     * Returns if the user has selected a lot of intervention
     *
     * @return {boolean}
     */
    isLotInterventionSelected() {
        return this.user.selected_lot_intervention !== null;
    }

    /**
     * Returns the logged user selected lot interventions
     *
     * @return {*}
     */
    getSelectedLotIntervention() {
        return this.user.selected_lot_intervention;
    }

    /**
     * Returns the logged user selected lot interventions with user entity team lot information
     *
     * @return {*}
     */
    getAllSelectedLotInformation() {
        const selectedLotIntervention = this.getSelectedLotIntervention();
        const userEntityTeamLot       = this.getUserEntityTeamLot();

        if (!selectedLotIntervention || !userEntityTeamLot) {
            return null;
        }

        return {
            user_entity_team_lot_id:         userEntityTeamLot.id,
            user_entity_team_lot_identifier: userEntityTeamLot.identifier,
            concession_id:                   selectedLotIntervention.concession.id,
            concession_name:                 selectedLotIntervention.concession.name,
            highway_id:                      selectedLotIntervention.highway.id,
            highway_name:                    selectedLotIntervention.highway.name,
            lot_intervention_type_id:        selectedLotIntervention.intervention_type.id,
            lot_intervention_type_name:      selectedLotIntervention.intervention_type.name,
            lot_intervention_id:             selectedLotIntervention.lot_intervention.id,
            lot_intervention_name:           selectedLotIntervention.lot_intervention.name,
            team_id:                         userEntityTeamLot.team.id,
            team_name:                       userEntityTeamLot.team.name,
            show_team_name:                  userEntityTeamLot.team.show_name,
            entity_id:                       userEntityTeamLot.entity.id,
            entity_name:                     userEntityTeamLot.entity.name,
            type_id:                         userEntityTeamLot.type.id,
            has_extra_permission:            userEntityTeamLot.type.has_extra_permission,
        };
    }

    /**
     * Returns the logged user selected lot interventions ids
     *
     * @return {*}
     */
    getSelectedLotInterventionIds() {
        const selectedLotIntervention = this.getSelectedLotIntervention();
        const userEntityTeamLot       = this.getUserEntityTeamLot();

        if (!selectedLotIntervention || !userEntityTeamLot) {
            return null;
        }

        return {
            concession_id:        selectedLotIntervention.concession.id,
            highway_id:           selectedLotIntervention.highway.id,
            intervention_type_id: selectedLotIntervention.intervention_type.id,
            lot_intervention_id:  selectedLotIntervention.lot_intervention.id,
            team_id:              userEntityTeamLot.team.id,
            entity_id:            userEntityTeamLot.entity.id,
            type_id:              userEntityTeamLot.type.id,
            show_team_name:       userEntityTeamLot.team.show_name,
        };
    }

    /**
     * Returns if the lot/intervention team should be shown
     */
    showLotInterventionTeam() {
        return this.user.entity_team_lot ? this.user.entity_team_lot.team.show_name : false;
    }

    /**
     * Returns the lot intervention display name
     *
     * @return {*}
     */
    getSelectedLotInterventionDisplayName(substring = false) {
        if (!this.getSelectedLotIntervention()) {
            return null;
        }

        let lotInterventionName = this.getSelectedLotIntervention().lot_intervention.name;

        if (substring && lotInterventionName.length > 140) {
            lotInterventionName = lotInterventionName.substring(0, 70) + ' ... ' + lotInterventionName.substring(lotInterventionName.length - 70);
        }

        return lotInterventionName;
    }

    /**
     * Returns the entity team display name
     *
     * @return {*}
     */
    getSelectedEntityTeamDisplayName() {
        if (this.showLotInterventionTeam()) {
            return `${this.getUserEntityTeamLot().entity.name} (${this.getUserEntityTeamLot().team.name})`;
        }

        return null;
    }

    /**
     * Returns the logged user selected lot interventions
     *
     * @return {*}
     */
    getUserEntityTeamLot() {
        return this.user.entity_team_lot;
    }

    /**
     * Returns true if the user is in the same lot intervention as the given id
     *
     * @param lotInterventionId
     * @return {*|boolean}
     */
    inSameLotIntervention(lotInterventionId) {
        return this.getSelectedLotIntervention() && this.getSelectedLotIntervention().lot_intervention.id === lotInterventionId;
    }

    get isBuilder() {
        return this.user.entity_team_lot
            && this.user.entity_team_lot.type.id === UserEntityTeamLotType.BUILDER;
    }

    get isRegulator() {
        return this.user.entity_team_lot
            && this.user.entity_team_lot.type.id === UserEntityTeamLotType.REGULATOR;
    }

    get isHighwayOperator() {
        return this.user.entity_team_lot
            && this.user.entity_team_lot.type.id === UserEntityTeamLotType.HIGHWAY_OPERATOR;
    }

    get isLotClosed() {
        return this.user.selected_lot_intervention.lot_intervention.is_closed;
    }

    /**
     * Returns if the user is using identifier
     *
     * @return {boolean}
     */
    isUsingIdentifier() {
        return this.user.entity_team_lot && this.user.entity_team_lot.identifier !== null;
    }

    /**
     * Returns the user identifier
     *
     * @return {*}
     */
    getIdentifier() {
        return this.user.entity_team_lot ? this.user.entity_team_lot.identifier : null;
    }

    /**
     * Returns if the user should select a lot of intervention
     *
     * @return {boolean}
     */
    shouldSelectLotIntervention() {
        return !this.isUsingIdentifier() && !this.isLotInterventionSelected();
    }

    /**
     * Returns the user display name
     *
     * @return {*}
     */
    getDisplayName() {
        if (this.isUsingIdentifier()) {
            return this.getIdentifier() + ' (' + this.user.display_name + ')';
        }

        return this.user.display_name;
    }
}
