import { Aurelia, inject, PLATFORM } from 'aurelia-framework';
import Echo                          from 'laravel-echo';
import Pusher                        from 'pusher-js';
import { AppContainer }              from 'resources/services/app-container';
import environment                   from '../../../config/environment.json';

@inject(Aurelia, AppContainer)
export class LaravelEchoService {

    instantiated = false;

    /**
     * Constructor
     *
     * @param aurelia
     * @param appContainer
     */
    constructor(aurelia, appContainer) {
        this.aurelia      = aurelia;
        this.appContainer = appContainer;
    }

    /**
     * Instantiates laravel echo
     */
    instantiate() {
        if (environment.webSockets.supported) {
            window.Pusher = Pusher;

            window.Echo = new Echo({
                broadcaster:       environment.webSockets.broadcaster.name,
                key:               environment.webSockets.broadcaster.key,
                wsHost:            environment.webSockets.broadcaster.wsHost,
                wsPort:            environment.webSockets.broadcaster.wsPort,
                forceTLS:          environment.webSockets.broadcaster.forceTLS,
                enabledTransports: environment.webSockets.broadcaster.enabledTransports,
                namespace:         environment.webSockets.broadcaster.namespace,
                authEndpoint:      environment.webSockets.broadcaster.authEndpoint,
                wssHost:           environment.webSockets.broadcaster.wssHost,
                wssPort:           environment.webSockets.broadcaster.wssPort,
                auth:              {
                    headers: {
                        'Authorization': 'Bearer ' + this.appContainer.localStorage.authToken(),
                    },
                },
            });

            this.instantiated = true;

            this.subscribeMaintenanceModeEvents();
        }
    }

    /**
     * Subscribes maintenance mode events
     */
    subscribeMaintenanceModeEvents() {
        window.Echo.channel('Ascendi.RumoQ.Events')
            .listen('MaintenanceModeInitiated', () => this.aurelia.setRoot(PLATFORM.moduleName('maintenance')))
            .listen('MaintenanceModeConcluded', () => window.location.reload(true));
    }

}
